import React from "react";
import { Sidebar } from "./sidebar.styles";
import { SidebarItem } from "./sidebar-item";
import { SidebarMenu } from "./sidebar-menu";
import { useSidebarContext } from "../../contexts/layout-context";
import { useLocation } from "react-router-dom";
import { MdBarChart, MdHomeWork, MdPeopleAlt } from "react-icons/md";
import { BsTable } from "react-icons/bs";
import { Divider } from "@nextui-org/react";

export const SidebarWrapper = () => {
    const { pathname } = useLocation();
    const { collapsed, setCollapsed } = useSidebarContext();

    return (
        <aside className="h-screen z-[41] sticky top-0">
            {collapsed ? (
                <div
                    className="bg-[rgb(15_23_42/0.3)] fixed inset-0 z-[49] opacity-80 transition-opacity md:hidden md:z-auto md:opacity-100"
                    onClick={setCollapsed}
                />
            ) : null}
            <div
                className={Sidebar({
                    collapsed: collapsed,
                })}
            >
                <div className="flex items-center gap-2 px-6">
                    {/* {company.logo} */}
                    <div className="flex flex-col gap-4">
                        <h3 className="text-xl font-medium m-0 text-default-900 whitespace-nowrap center">Impactt Solutions</h3>
                    </div>
                </div>
                <div className="flex flex-col justify-between h-full">
                    <div className="flex flex-col gap-6 mt-3 px-2">
                        <Divider />
                        <SidebarMenu>
                            <SidebarItem href="/payments" isActive={pathname.includes("/payments")} title="Payments" icon={<BsTable size={20} />} />
                        </SidebarMenu>
                    </div>
                </div>
            </div>
        </aside>
    );
};
