import { useSearchParams } from "react-router-dom";
import useDebounce from "./useDebounce";
import { useCallback } from "react";

const DEFAULT_QUERY_NAME = "query";

export default function useSearchParamsState(searchParamName, defaultValue, delay = 0) {
    const [searchParams, setSearchParams] = useSearchParams();

    const acquiredSearchParam = searchParams.get(DEFAULT_QUERY_NAME);

    const [debouncedSearchParamsState, searchParamsState, setSearchParamsStateValue] = useDebounce(
        JSON.parse(acquiredSearchParam)?.[searchParamName] ?? defaultValue,
        delay,
    );

    function setSearchParamsState(newState) {
        const next = JSON.parse(acquiredSearchParam ?? "{}");

        // if the state is empty, remove it from the search params, otherwise set it to the new state.
        if (!newState) delete next[searchParamName];
        else next[searchParamName] = newState;

        setSearchParams({ [DEFAULT_QUERY_NAME]: JSON.stringify(next) });
        setSearchParamsStateValue(newState);
    }

    return { value: searchParamsState, debouncedValue: debouncedSearchParamsState, set: setSearchParamsState };
}
