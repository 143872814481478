import { useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Divider,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    useDisclosure,
} from "@nextui-org/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Axios from "axios";
import { useState } from "react";
export default function Login() {
    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const login = useMutation({
        mutationFn: async (data) => {
            const response = await Axios({
                method: "POST",
                url: `/login`,
                data,
            });
            return response;
        },
        onSuccess: function (response) {
            toast.success(response.data.message);
            navigate("/");
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    });

    const handleSubmit = (event) => {
        login.mutate(formData);
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <Card className="min-w-[400px]">
                <CardHeader className="flex gap-3">
                    <div className="flex flex-col">Login</div>
                </CardHeader>
                <CardBody className="gap-3">
                    <Input
                        size="sm"
                        type="text"
                        label="Username"
                        name="username"
                        value={formData.username}
                        onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                    />
                    <Input
                        size="sm"
                        type="password"
                        label="Password"
                        name="password"
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    />
                    <Button color="primary" type="submit" onClick={handleSubmit}>
                        Sign In
                    </Button>
                </CardBody>
            </Card>
        </div>
    );
}
