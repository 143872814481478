import {
    Avatar,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownSection,
    DropdownTrigger,
    Input,
    Navbar,
    NavbarContent,
    Spinner,
} from "@nextui-org/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Axios from "axios";
import { StatusCodes } from "http-status-codes";
import React, { useEffect, useState, Suspense } from "react";
import { MdHelp, MdNightlight, MdOutlineLogout, MdSearch, MdSettings, MdSunny } from "react-icons/md";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useLockedBody } from "../../hooks/useBodyLock";
import { SidebarContext } from "../../contexts/layout-context";
import { SidebarWrapper } from "../../components/sidebar/sidebar";
import useDebounce from "../../hooks/useDebounce";
import { useSidebarContext } from "../../contexts/layout-context";
import useUpdateDocumentHead from "../../hooks/useUpdateDocumentTitle";

export default function Dashboard() {
    // const title = useUpdateDocumentTitle("Dashboard | Impactt Solutions");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [_, setLocked] = useLockedBody(false);
    const handleToggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setLocked(!sidebarOpen);
    };
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const location = useLocation();
    const [debouncedSearch, search, setSearch] = useDebounce(
        {
            [location.pathname]: "",
        },
        1000,
    );

    // queries
    const { data: userData, ...userQuery } = useQuery({
        queryKey: ["user"],
        queryFn: async function () {
            const response = await Axios({ method: "GET", url: `/user-data` });
            return response.data.data;
        },
    });

    // mutation
    const logoutMutation = useMutation({
        mutationFn: async function () {
            const response = await Axios({ method: "GET", url: `${import.meta.env.VITE_BASE_URL}/logout` });
            return response;
        },
        onSuccess: function (response) {
            // remove all the cached query data
            queryClient.clear();
            navigate("/get-started", { replace: true });
            toast.success(response.data.message);
        },
    });

    function handleSearchChange(event) {
        setSearch({
            ...search,
            [location.pathname]: event.target.value,
        });
    }

    function clearSearch() {
        setSearch({
            ...search,
            [location.pathname]: "",
        });
    }

    // insert empty search value on path change or keep old value if already exists
    useEffect(() => {
        setSearch((previousValues) => {
            if (previousValues[location.pathname] === undefined) {
                return {
                    ...previousValues,
                    [location.pathname]: "",
                };
            }

            return previousValues;
        });
    }, [location.pathname]);

    return (
        <SidebarContext.Provider
            value={{
                collapsed: sidebarOpen,
                setCollapsed: handleToggleSidebar,
            }}
        >
            {useUpdateDocumentHead({ title: "Dashboard | Impactt Solutions" })}
            <section className="flex">
                <SidebarWrapper />
                <div className="relative flex flex-col flex-1 overflow-x-hidden">
                    <Navbar
                        isBordered
                        className="w-full"
                        classNames={{
                            wrapper: "w-full max-w-full",
                        }}
                    >
                        <NavbarContent>
                            <BurgerButton />
                        </NavbarContent>

                        <Input
                            variant="underlined"
                            size="sm"
                            placeholder="Search"
                            isClearable
                            startContent={<MdSearch size={20} />}
                            value={search[location.pathname]}
                            onClear={clearSearch}
                            className="ms-10 me-3"
                            onChange={handleSearchChange}
                        />

                        <NavbarContent justify="end">
                            <Dropdown placement="bottom-end" closeOnSelect={true}>
                                <DropdownTrigger>
                                    <Avatar
                                        isBordered
                                        as="button"
                                        className="transition-transform"
                                        // color="success"
                                        name={userData?.name}
                                        size="sm"
                                        src={userData?.picture}
                                    />
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Profile Actions" variant="flat" disabledKeys={["settings", "profiles", "help"]}>
                                    <DropdownSection showDivider>
                                        <DropdownItem key="profile" className="h-14 gap-2" textValue="User details">
                                            <p className="font-semibold">{`Hi, ${userData?.name}`}</p>
                                            <p className="">{userData?.email}</p>
                                        </DropdownItem>
                                    </DropdownSection>
                                    <DropdownSection>
                                        <DropdownItem
                                            key="logout"
                                            color="danger"
                                            className="text-danger"
                                            onClick={logoutMutation.mutate}
                                            startContent={<MdOutlineLogout size={20} />}
                                            textValue="Log user out"
                                        >
                                            Log Out
                                        </DropdownItem>
                                    </DropdownSection>
                                </DropdownMenu>
                            </Dropdown>
                        </NavbarContent>
                    </Navbar>
                    <div className="bg-background">
                        <Suspense
                            fallback={
                                <div className="flex items-center justify-center" style={{ minHeight: "calc(100vh - 100px)" }}>
                                    <Spinner />
                                </div>
                            }
                        >
                            <Outlet context={{ globalSearch: debouncedSearch }} />
                        </Suspense>
                    </div>
                </div>
            </section>
        </SidebarContext.Provider>
    );
}

export const BurgerButton = () => {
    const { collapsed, setCollapsed } = useSidebarContext();

    return (
        <div
            className="absolute flex flex-col justify-around w-6 h-6 bg-transparent border-none cursor-pointer padding-0 z-[202] focus:outline-none [&_div]:w-6 [&_div]:h-px [&_div]:bg-default-900 [&_div]:rounded-xl  [&_div]:transition-all  [&_div]:relative  [&_div]:origin-[1px] "
            onClick={setCollapsed}
        >
            <div />
            <div />
        </div>
    );
};
