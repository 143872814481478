import { StatusCodes } from "http-status-codes";

export function showFormErrors(error, form) {
    if (error?.response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        for (const [key, errorValue] of Object.entries(error.response.data.errors)) {
            form.setFieldError(key, errorValue);
        }
    }
}

export function showValueOrBlank(value) {
    if (["", null, undefined, "Invalid date"].includes(value)) return "-";
    return value;
}

export function formatCurrency(value, prefix) {
    const output = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
    }).format(value ?? 0);

    if (prefix) return output.replace("₹", prefix);
    return output;
}
