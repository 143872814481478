import { tv } from "@nextui-org/react";

export const SidebarWrapper = tv({
    base: "bg-background transition-transform h-full fixed -translate-x-full w-64 shrink-0 z-[50] overflow-y-auto border-r border-divider flex-col py-6 px-3 md:ml-0 md:flex md:static md:h-screen md:translate-x-0 ",

    variants: {
        collapsed: {
            true: "translate-x-0 ml-0 [display:inherit]",
        },
    },
});

export const Sidebar = Object.assign(SidebarWrapper, {});
