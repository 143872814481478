export default {
    FORMAT: {
        DATE: "Do MMM YYYY",
        DATE_TIME: "Do MMM YYYY hh:mm:ss A",
    },
    STATUS: {
        PAYMENT: {
            PENDING: "Pending",
            PAID: "Paid",
            SUCCESS: "Success",
            FAILED: "Failed",
        },
    },
};
