import { lazy } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Dashboard from "./views/dashboard/dashboard";
import Axios from "axios";
// import GetStarted from "./views/auth/get-started";
import { NextUIProvider } from "@nextui-org/react";
import { StatusCodes } from "http-status-codes";
import { MdOutlineWifi, MdOutlineWifiOff } from "react-icons/md";
import Login from "./views/auth/login";
import Payments from "./views/dashboard/payments/payments";

// const Payments = lazy(() => import("./views/dashboard/payments/payments"));

// set `baseURL` and `withCredentials` options in axios
Axios.defaults.baseURL = import.meta.env.VITE_BASE_URL;
Axios.defaults.withCredentials = true;
Axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.config?.url !== "/login") {
            if (error?.response) {
                const { status } = error.response;
                if (status === StatusCodes.UNAUTHORIZED || status === StatusCodes.FORBIDDEN) {
                    window.location.href = `${window.location.origin}/login`;
                }
            } else {
                console.error("Network Error:", "Unable to connect to the server");
                toast.error("Unable to connect to the server");
            }
        }
        return Promise.reject(error);
    },
);

const App = () => {
    const navigate = useNavigate();
    const { onLine } = navigator;
    !onLine &&
        toast.error("Connect to internet.", {
            icon: <MdOutlineWifiOff size={25} />,
        });

    window.ononline = (event) => {
        toast.success("You're back online.", {
            icon: <MdOutlineWifi size={25} />,
        });
    };

    window.onoffline = (event) => {
        toast.error("No internet.", {
            icon: <MdOutlineWifiOff size={25} />,
        });
    };

    return (
        <NextUIProvider navigate={navigate}>
            <Toaster
                position="bottom-center"
                toastOptions={{
                    duration: 5000,
                    style: {
                        color: "#ECEDEE",
                        backgroundColor: "#3f3f46",
                    },
                    success: {
                        style: {
                            backgroundColor: "#12a150",
                        },
                        iconTheme: {
                            primary: "#ECEDEE",
                            secondary: "#12a150",
                        },
                    },
                    error: {
                        style: {
                            backgroundColor: "#f31260",
                        },
                        iconTheme: {
                            primary: "#ECEDEE",
                            secondary: "#f31260",
                        },
                    },
                }}
            />
            <Routes>
                {/* later replace this with login component */}
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Dashboard />}>
                    <Route path="payments" element={<Payments />} />
                </Route>
                <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
        </NextUIProvider>
    );
};

export default App;
