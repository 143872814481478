import {
    Button,
    Chip,
    DatePicker,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Pagination,
    Spinner,
    Select,
    SelectItem,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    useDisclosure,
    Input,
} from "@nextui-org/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Axios from "axios";
import startCase from "lodash/startCase";
import truncate from "lodash/truncate";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useOutletContext } from "react-router-dom";
import CONSTANTS from "../../../constants";
import useSearchParamsState from "../../../hooks/useSearchParamsState";
import { formatCurrency, showValueOrBlank } from "../../../utils/utils";
import { useFormik } from "formik";
import * as Yup from "yup";

const COLUMNS = [
    {
        label: "User",
    },
    {
        label: "Phone number",
    },
    {
        label: "Transaction Id",
    },
    {
        label: "Amount",
    },
    {
        label: "Image",
    },
    {
        label: "Created On",
    },
    {
        label: "Status",
    },
    {
        label: "Action",
    },
];

const PILL_COLOR = {
    Pending: "default",
    Paid: "warning",
    Success: "success",
    Failed: "danger",
};

const STATUS_OPTIONS = Object.values(CONSTANTS.STATUS.PAYMENT).map((status) => ({ key: status, value: status }));

export default function Payments() {
    const queryClient = useQueryClient();
    const { globalSearch } = useOutletContext();
    const location = useLocation();

    const { isOpen: imageModalIsOpen, onOpen: imageModalOnOpen, onClose: imageModalOnClose } = useDisclosure();
    const { isOpen: rejectionModalIsOpen, onOpen: rejectionModalOnOpen, onClose: rejectionModalOnClose } = useDisclosure();

    const search = globalSearch[location.pathname];
    const [status, setStatus] = useState(new Set([CONSTANTS.STATUS.PAYMENT.PAID]));
    const [selectedPayment, setSelectedPayment] = useState({});
    const { value: page, set: setPage } = useSearchParamsState("page", 1);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const filters = useMemo(
        () => ({
            page,
            start_date: startDate?.toString(),
            end_date: endDate?.toString(),
            search,
            status: Array.from(status),
        }),
        [page, startDate, endDate, search, status],
    );

    const { data: payments, ...paymentsQuery } = useQuery({
        queryKey: ["payments", filters],
        queryFn: async function () {
            const response = await Axios.get("/payments", { params: filters });
            return response.data.data;
        },
    });

    const updatePaymentStatus = useMutation({
        mutationFn: async function ({ applicationId, status, remarks }) {
            const response = await Axios({
                method: "POST",
                url: `/payment`,
                data: {
                    applicationId,
                    status,
                    remarks,
                },
            });
            return response;
        },
        onSuccess: function (response) {
            toast.success(response?.data?.message);
            rejectionModalOnClose();

            // update table with the new data
            const updatedDoc = response?.data?.data;

            queryClient.setQueriesData({ queryKey: ["payments"] }, function (oldData) {
                return { ...oldData, docs: oldData?.docs?.map((data) => (data._id === updatedDoc._id ? updatedDoc : data)) };
            });
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message);
        },
    });

    const rejectionForm = useFormik({
        initialValues: {
            remarks: "",
        },
        validationSchema: Yup.object({
            remarks: Yup.string().required("Rejection reason is required.").min(10, "Reason is too short.").max(256, "Reason is too long."),
        }),
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: function (values) {
            // do rejection mutation
            const { remarks } = values;
            updatePaymentStatus.mutate({ applicationId: selectedPayment?.application, status: "Failed", remarks });
        },
    });

    function renderCell(paymentData, columnKey) {
        switch (columnKey) {
            case "User":
                return showValueOrBlank(startCase(truncate(paymentData?.user?.first_name)));
            case "Phone number":
                return showValueOrBlank(startCase(truncate(paymentData?.user?.phone_no)));
            case "Transaction Id":
                return showValueOrBlank(paymentData?.payment_id);
            case "Amount":
                return formatCurrency(paymentData?.amount);
            case "Image":
                return (
                    <Button
                        size="sm"
                        onClick={() => {
                            setSelectedPayment(paymentData);
                            imageModalOnOpen();
                        }}
                    >
                        Show
                    </Button>
                );
            case "Status":
                return (
                    <Chip className="capitalize" color={PILL_COLOR[paymentData.status]} size="sm" variant="flat">
                        {paymentData.status}
                    </Chip>
                );
            case "Created On":
                return showValueOrBlank(truncate(moment(paymentData?.createdAt).format(CONSTANTS.FORMAT.DATE_TIME)));
            case "Action":
                return (
                    <div className="flex gap-2">
                        <Button
                            flat
                            size="sm"
                            color="success"
                            isDisabled={!["Paid", "Failed"].includes(paymentData.status) || updatePaymentStatus.isPending}
                            onClick={() => updatePaymentStatus.mutate({ applicationId: paymentData?.application, status: "Success" })}
                            aria-label="Approve payment"
                            isLoading={updatePaymentStatus.isPending}
                        >
                            {paymentData?.status === "Success" ? "Approved" : "Approve"}
                        </Button>
                        <Button
                            flat
                            size="sm"
                            isDisabled={!["Paid", "Success"].includes(paymentData.status)}
                            color="danger"
                            onClick={() => {
                                setSelectedPayment(paymentData);
                                rejectionModalOnOpen();
                            }}
                            aria-label="Reject payment"
                        >
                            {paymentData?.status === "Failed" ? "Rejected" : "Reject"}
                        </Button>
                    </div>
                );
            default:
                return "-";
        }
    }

    return (
        <div>
            {/* filters */}
            <div className="flex gap-3 pb-2 mt-2 mx-2" style={{ overflowX: "auto" }}>
                <Select
                    label="Status"
                    placeholder="Select payment status"
                    labelPlacement="outside"
                    size="sm"
                    variant="bordered"
                    selectionMode="multiple"
                    onSelectionChange={setStatus}
                    selectedKeys={status}
                >
                    {STATUS_OPTIONS.map((status) => (
                        <SelectItem key={status.key}>{status.value}</SelectItem>
                    ))}
                </Select>
                <DatePicker
                    type="date"
                    size="sm"
                    label="Select starting date"
                    placeholder="something"
                    variant="bordered"
                    labelPlacement="outside"
                    className="min-w-[200px]"
                    value={startDate}
                    onChange={setStartDate}
                    showMonthAndYearPickers
                />
                <DatePicker
                    type="date"
                    size="sm"
                    label="Select end date"
                    placeholder="something"
                    variant="bordered"
                    labelPlacement="outside"
                    className="min-w-[200px]"
                    value={endDate}
                    showMonthAndYearPickers
                    onChange={setEndDate}
                />
            </div>
            <Divider className="my-2" />
            <div
                className="m-2"
                style={{
                    maxHeight: "calc(100vh - 220px)",
                    overflow: "auto",
                }}
            >
                <Table isHeaderSticky={true} removeWrapper={true} isStriped={true} hideHeader={!payments?.docs?.length}>
                    <TableHeader columns={COLUMNS}>{(column) => <TableColumn key={column.label}>{column.label}</TableColumn>}</TableHeader>
                    <TableBody
                        items={(paymentsQuery.isSuccess && payments?.docs) || []}
                        isLoading={paymentsQuery.isLoading}
                        loadingContent={<Spinner label="Loading..." />}
                        emptyContent={paymentsQuery.isSuccess && payments?.docs?.length === 0 && <p>No data to display.</p>}
                    >
                        {(item) => (
                            <TableRow key={item._id}>
                                {(columnKey) => <TableCell className=" align-top">{renderCell(item, columnKey)}</TableCell>}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>

            {/* table */}
            {payments?.docs?.length > 0 && (
                <>
                    <div className="flex flex-row mx-4">
                        <p className="text-default-500">
                            Showing {(payments?.page - 1) * payments?.limit} to{" "}
                            {Math.min(payments?.page * payments?.limit, (payments?.page - 1) * payments?.limit + payments?.docs?.length)} of{" "}
                            {payments?.totalDocs}
                        </p>
                        <Pagination
                            showControls
                            isCompact={true}
                            total={payments?.totalPages}
                            page={payments?.page}
                            className="ms-auto"
                            size="sm"
                            onChange={(page) => setPage(page)}
                        />
                    </div>
                </>
            )}

            {/* image modal */}
            <Modal size="full" isOpen={imageModalIsOpen} onClose={imageModalOnClose} scrollBehavior="inside">
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Transaction Image</ModalHeader>
                            <ModalBody>
                                <div className="flex flex-wrap gap-5">
                                    <Input
                                        label="User"
                                        value={selectedPayment?.user?.first_name}
                                        className="w-64"
                                        disabled
                                        isReadOnly
                                        labelPlacement="outside"
                                    />
                                    <Input
                                        label="Payment ID"
                                        value={selectedPayment?.payment_id}
                                        className="w-64"
                                        disabled
                                        isReadOnly
                                        labelPlacement="outside"
                                    />
                                    <Input
                                        label="Amount"
                                        value={selectedPayment?.amount}
                                        className="w-64"
                                        disabled
                                        labelPlacement="outside"
                                        isReadOnly
                                    />
                                </div>
                                <Divider className="my-2" />
                                <img src={`${import.meta.env.VITE_FILES_URL}${selectedPayment?.screenshot}`} />
                            </ModalBody>

                            <ModalFooter>
                                <Button color="default" variant="solid" onPress={onClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal
                size="sm"
                isOpen={rejectionModalIsOpen}
                onClose={rejectionModalOnClose}
                scrollBehavior="inside"
                isDismissable={false}
                isKeyboardDismissDisabled={true}
                hideCloseButton
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Reject Transaction</ModalHeader>
                            <ModalBody>
                                <Input
                                    name="remarks"
                                    isRequired
                                    labelPlacement="outside"
                                    type="text"
                                    variant="faded"
                                    label="Rejection Reason"
                                    placeholder="Rejected due to..."
                                    isInvalid={!!rejectionForm.errors.remarks}
                                    errorMessage={rejectionForm.errors.remarks}
                                    onChange={(event) => {
                                        rejectionForm.handleChange(event);
                                    }}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="default" variant="solid" onPress={onClose} isDisabled={updatePaymentStatus.isPending}>
                                    Cancel
                                </Button>
                                <Button
                                    color="danger"
                                    variant="solid"
                                    onPress={rejectionForm.handleSubmit}
                                    disabled={!rejectionForm.isValid || updatePaymentStatus.isPending}
                                    isLoading={updatePaymentStatus.isPending}
                                >
                                    Reject
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
}
